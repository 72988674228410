<template>
  <div class="wrapper">
    <div class="wrapper__card">
      <div class="wrapper__card__header">
        <div class="left"></div>

        <div class="right">
          <div>
            <z-btn
              small
              rounded
              text="voltar"
              icon="$keyboard_return_rounded"
              @click="$router.go(-1)"
            />
          </div>
          <div>
            <z-btn rounded primary text="Criar filtro" icon="mdi-plus" small @click="goToPage('filterIndexPage')"/>
          </div>
        </div>
      </div>

      <div class="wrapper__card__body">
        <div class="wrapper__card__body__table">
          <z-table
            :headers="headers"
            :items="items"
            :current-page="currentPage"
            :first-item-index="(currentPage - 1) * itemsPerPage + 1"
            :number-of-pages="numberOfPages"
            :last-item-index="
              currentPage * itemsPerPage > numberOfRepos
                ? numberOfRepos
                : currentPage * itemsPerPage
            "
            @next="onNext"
            @previous="onPrevious"
            @change:page="onChangePage"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <div class="actions">
                <v-icon @click="doSome(item)" class="mr-2"
                  >$restore_from_trash_rounded</v-icon
                >
                <v-icon @click="doSome(item)">$edit_outline</v-icon>
              </div>
            </template>
          </z-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 10,
      numberOfRepos: null,
      items: [
        {
          id: 0,
          name: "2023_email",
          creator: "Jorge Soares Silva",
          last_update: "09/01/2023",
        },
        {
          id: 1,
          name: "2023_email",
          creator: "Jorge Soares Silva",
          last_update: "09/01/2023",
        },
        {
          id: 2,
          name: "2023_email",
          creator: "Jorge Soares Silva",
          last_update: "09/01/2023",
        },
        {
          id: 3,
          name: "2023_email",
          creator: "Jorge Soares Silva",
          last_update: "09/01/2023",
        },
        {
          id: 4,
          name: "2023_email",
          creator: "Jorge Soares Silva",
          last_update: "09/01/2023",
        },
        {
          id: 5,
          name: "2023_email",
          creator: "Jorge Soares Silva",
          last_update: "09/01/2023",
        },
        {
          id: 6,
          name: "2023_email",
          creator: "Jorge Soares Silva",
          last_update: "09/01/2023",
        },
      ],
      headers: [
        { text: "NOME DO FILTRO", value: "name", sortable: false },
        { text: "CRIADOR", value: "creator" },
        { text: "ÚLTIMA ATUALIZAÇÃO", value: "last_update" },
        { text: "AÇÕES", value: "actions" },
      ],
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.numberOfRepos / this.itemsPerPage);
    },
  },
  methods: {
    doSome() {},
    goToPage(name) {
      this.$router.push({ name });
    },
    onNext() {
      this.currentPage += 1;
      // this.fetchRepos();
    },
    onPrevious() {
      this.currentPage -= 1;
      // this.fetchRepos();
    },
    onChangePage(page) {
      this.currentPage = +page;
      // this.fetchRepos();
    },

    // async fetchNumberOfRepos() {
    //   const resp = await fetch(`${BASE_URL}/orgs/vuejs`);

    //   const obj = await resp.json();

    //   this.numberOfRepos = obj.public_repos;
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.wrapper {
  color: $z-black-1;
  padding: $z-s-1;
  height: calc(100vh - 48px);
  &__card {
    padding: $z-s-1;
    background: #ffffff;
    border-radius: 15px;
    height: 100%;
    overflow-y: auto;
    @include trackScrollBar;

    &__header {
      display: flex;
      align-items: center;
      gap: $z-s-1;
      margin-bottom: $z-s-2;
      flex-wrap: wrap;
      .left {
        flex: 1 1 auto;
        span {
          font-weight: 700;
          font-size: 1.1em;
          line-height: 15px;
        }
      }
      .right {
        flex: 2 1 600px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        gap: $z-s-1 0.5rem;

        @media (max-width: 808px) {
          justify-content: flex-start;
          & > div {
            flex: 1 1 350px;
          }
        }
      }
    }
    &__body {
      //   display: flex;
      //   flex-direction: column;
      //   gap: $z-s-1;
      //   &__cards {
      //   }

      &__table {
      }
    }
  }
}
</style>
